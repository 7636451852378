import Icon from '../Icon';
import { Badge } from 'antd';
import { useTranslation } from "react-i18next";

const FooterEle = ({ icon, label, handleSubmit, paraIcon, badge, file, setFile, edit = false, hideInput = false , save, isNeedImage}) => {
  const { t } = useTranslation();

  return (
    <div className='flex justify-between'>
      <div className='flex gap-4 items-center'>
        {label && 
        
          !hideInput &&
          <>
          <label htmlFor="fileInput"><Icon name={icon} /></label>
            <input onChange={(e) => setFile(e.target.files[0])} className='hidden' type="file" id="fileInput" />
            <div className='flex gap-3 relative'>
            {paraIcon && <Icon name={paraIcon} />}
            {file?.name || label}
            
            <div className='absolute -top-3 left-3.5'>
            {badge && <Badge count={1} className='w-2' color='black' />}
            </div>
            
            </div>
            </>
          

        }
      </div>
  
      <button
        onClick={handleSubmit}
        className='px-3 text-white items-center bg-orange rounded-lg py-2 flex gap-5'
      >
       {
          save ? (
            <>
              <p>{t("saveBtn")}</p>
              <Icon name="Pencil" size={18} />
            </>

          ) : edit ? (
            <>
              <p>{t("EditBtn")}</p>
              <Icon name="Pencil" size={18} />
            </>
          ) : (
            <>
              <p>{t("createBtn")}</p>
              <Icon name="Plus" />
            </>
          )
        }
      </button>
    </div>
  );
};

export default FooterEle;
