const validateFields = (fields) => {

    let isValid = true;
    const updatedFields = fields.map((field) => {
      let fieldValue = field.value || '';
      let errorMessage = '';
      
      if (!fieldValue && field.type !== 'date') {
        isValid = false;
        errorMessage = `${field.name} est requis`;
      }

      if (field.type === 'date') {
        const startDateField = fields.find(f => f.code === 'startDate');
        const endDateField = fields.find(f => f.code === 'endDate');
   
        if (startDateField && endDateField) {
            const startDate = new Date(startDateField.value);
            const endDate = new Date(endDateField.value);

        } else {
            isValid = false;
            errorMessage = "Les champs 'Date de début' et 'Date de fin' sont requis pour les dates";
        }
    }

      if (field.code === 'email' && fieldValue) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(fieldValue)) {
          isValid = false;
          errorMessage = 'Please enter a valid email address.';
        }
      }
  
      if (field.code === 'phone' && fieldValue) {
        console.log(fieldValue);
        const phoneRegex = /^[1-9]{1}[0-9]{9,14}$/;
        if (!phoneRegex.test(fieldValue)) {
          isValid = false;
          errorMessage = 'Please enter a valid 10-digit phone number.';
        }
      }
  
      return {
        ...field,
        error: errorMessage,
      };
    });
  
    return { updatedFields, isValid };
  };

export default validateFields;