import React, { useState, useEffect } from "react";
import InputField from "../InputField";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment";
import { DatePicker } from "antd";

const ContartInputEdit = ({ formData, setFormData }) => {
    const name = "Contrat";
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { t } = useTranslation();


    useEffect(() => {
      const startField = formData.fields?.find((field) => field.code === "startDate");
      const endField = formData.fields?.find((field) => field.code === "endDate");

      if (startField && startField.value) {
          setStartDate(moment(startField.value)); 
        }
        
        if (endField && endField.value) {
            setEndDate(moment(endField.value)); 
        }

  }, [formData]);

    const disabledStartDate = (current) => {
        return current && current < moment().startOf("day");
    };

    const disabledEndDate = (current) => {
        return current && startDate && current < startDate.startOf("day");
    };

    const handleChangeInput = (e, index, fiealdType = null, dateType = null) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === "checkbox" ? checked : value;

        const updatedFields = [...formData.fields];

        if (fiealdType === "location") {
            updatedFields[index] = {
                ...updatedFields[index],
                value: value ? value.label : "",
            };
        } else if (fiealdType === "date") {
            if (dateType === "startDate") {
                setStartDate(value);
                updatedFields[index] = {
                    ...updatedFields[index],
                    value: value,
                };
            } else if (dateType === "endDate") {
                setEndDate(value);
                updatedFields[index] = {
                    ...updatedFields[index],
                    value: value,
                };
            }
        } else {
            updatedFields[index] = {
                ...updatedFields[index],
                value: fieldValue,
            };
        }

        setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
        }));
    };

    return (
        <div className="flex flex-col gap-5">
            {formData.fields?.map((field, index) => (

                <div key={index}>
                  
                    {field.type === "text" && (
                        <>
                            <InputField
                                handleChange={(e) => handleChangeInput(e, index)}
                                value={field.value || ""}
                                type={field.type}
                                name={field.name}
                                placeholder={field.name}
                            />
                        </>
                    )}
                    {field.type === "date" && (
                        <>
                
                {field.code === 'startDate' &&
                          <div className="flex flex-col gap-2">
            
                           <DatePicker
                                 style={{
                                   height: "3rem"
                                  }}
                                  placeholder="Start Date"
                                  value={startDate || null} 
                                  onChange={(value) =>
                                    handleChangeInput({ target: { value } }, index,"date", "startDate")
                                  }
                                  disabledDate={disabledStartDate}
                              />
                          </div>
                          }
                          {field.code === 'endDate' &&
                            <div className="flex flex-col gap-2 ">
                              <DatePicker
                                  style={{
                                    
                                    height: "3rem"
                                  }}
                                  placeholder="End Date"
                                  value={endDate || null} 
                                  disabled={!startDate} 
                                  onChange={(value) =>
                                    handleChangeInput({ target: { value } }, index,"date", "endDate")
                                  }
                                  disabledDate={disabledEndDate}
                                  />
                            </div>
                          }
                        </>
                    )}
                    {field.type === "checkbox" && (
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id={field.name}
                                checked={field.value}
                                onChange={(e) => handleChangeInput(e, index)}
                            />
                            <label htmlFor={field.name} className="text-gray-800 capitalize">
                                {field.name}
                            </label>
                        </div>
                    )}
                    {field.type === "location" && (
                        <div className="relative">
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                selectProps={{
                                    onChange: (value) =>
                                        handleChangeInput(
                                            { target: { name: field.name, value } },
                                            index,
                                            "location"
                                        ),
                                    value: field.value ? { label: field.value, value: field.value } : null,
                                    styles: {
                                        input: (provided) => ({
                                            ...provided,
                                            margin: "8px",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "8px",
                                        }),
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ContartInputEdit;
