import React from "react";
import InputField from "./InputField";
import PhoneInputField from "./PhoneInput";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import validateFields from "../utils/validateFields";


const DynamicInputs = ({data, handleChangeInput, formData}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-5">
            {data.map((field, index) => (
                 field.isActive && (
                    <div key={index}>
                    {field.type === 'text' && (
                        <>
                      <InputField
                      handleChange={(e) => handleChangeInput(e, index)} 
                      value={formData.fields[index]?.value || ''} 
            
                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                      />
                        {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                     
                    </>
                  )}
                  {field.type === 'tel' && (
                      <>
                      <PhoneInputField
                        handleChange={(value) => handleChangeInput({ target: { name: field.name, value } }, index)}
                        value={formData.fields[index]?.value || ''} 

                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                        />
                         {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                     
                    </>
                  )}
                  
                  {field.type === 'textarea' && (
                      <>
                      <InputField
                          handleChange={(e) => handleChangeInput(e, index)} 
                          value={formData.fields[index]?.value || ''} 

                    
                          type={field.type}
                          name={field.name}
                          placeholder={field.name}
                          />
                       {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                     
                    </>
                  )} 
                  {field.type === 'checkbox' && (
                      <div key={field} className="flex items-center">
                    <input
                              type="checkbox"
                              id={field.name}
                              className="mr-2"
                              />
                          <label htmlFor={field} className="text-gray-800 capitalize">
                              {field.name}
                          </label>
                        </div>
                  )} 
                   {field.type === 'location' && (
                       <div className='relative'>
      
                        <div className={`absolute left-3 transition-all -top-3 bg-white text-sm  px-1 text-dark font-medium z-10 `} >
                            {t("address")}
                        </div>
                        <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY} 
                            selectProps={{
                              onChange: (value) => handleChangeInput({ target: { name: field.name, value: value } }, index),  
                            styles: {
                                input: (provided) => ({
                                  ...provided,
                                  margin: '8px',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    margin: '8px',
                                }),
                                option: (provided) => ({
                                ...provided,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                margin: '8px',
                            }),
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '8px',
                                }),
                            },
                        }}
                        />
                                        {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                     
                     </div>
                  )} 
                </div>

                 )
            ))}
        </div>
    );
};

export default DynamicInputs;