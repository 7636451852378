import React from 'react'
import { useTranslation } from "react-i18next";

const InterfacePubliqueTabs = ({ data, setSelectedTab, selectedTab }) => {
    const { t } = useTranslation();
    const getTextColor = (code) => {
        switch (code) {
            case "FAIBLE":
                return "text-[#16a34a]";
            case "MOYEN":
                return "text-[#ca8a04]";
            case "HAUTE":
                return "text-[#ea580c]";
            case "CRITIQUE":
                return "text-[#dc2626]";
            default:
                return "text-gray-600";
        }
    };

    return (
        <div className='flex w-full border rounded-2xl border overflow-hidden'>
            {
                data.map(d => (
                    <div key={d.id} onClick={() => setSelectedTab(d.code)} className={`font-semibold tab w-full border border-y-0 border-l-0 border-r-2 capitalize 
                        ${selectedTab === d.code ? "bg-[#302f38] active text-white" : getTextColor(d.code)} 
                        cursor-pointer divide-x py-3 flex items-center justify-center`} >
                        {t(d.name)}
                    </div>
                ))
            }
        </div>
    )
}

export default InterfacePubliqueTabs