import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import api from "../utils/api";

const PubliArea = () => {
    const [backgroundImage, setBackgroundImage] = useState('');
    const [publicAreas, setPublicAreas] = useState([]);
    const {InterfaceId}  = useParams();


    useEffect(() => {

        const fetchPublicAreas = async () => {
          try {
            const response = await api.get(`/interface/${InterfaceId}`); 
            setPublicAreas(response.data[0].fields);
            setBackgroundImage(response.data[0].image);
        
          } catch (err) {
            console.log('Failed to fetch public areas'); 
          }
        };
    
        fetchPublicAreas(); 
    
      }, []);

    return (
        <div className="min-h-screen bg-gray-100 p-6 flex items-center justify-center"
        style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',   
            backgroundPosition: 'center'
          }}
        >
            <div className="flex flex-col gap-5 max-w-md w-full p-6 bg-white shadow-lg rounded-lg  ">
                <div>
                    <button>
                        
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PubliArea