import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../utils/api";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Select } from "antd";
import InputField from '../../components/InputField';
import { showErrorToast } from "../../utils/toastConfig";

const SettingsClientDrawer = ({fields, setFields, selectedFields, setSelectedFields, handleCheckboxChange,Link, name,ifLink}) =>{
    const { t } = useTranslation();
    const [showInput, setShowInput] = useState(false);
    const [newField, setNewField] = useState('');
    const [fieldType, setFieldType] = useState('');
    const [copied, setCopied] = useState(false);
    const options = [
        { label: 'Text', value: 'text' },
        { label: 'Number', value: 'tel' },
        { label: 'Location', value: 'location' },
        { label: 'check box', value: 'checkbox' },
    ];



    const copyToClipboard = () => {
        navigator.clipboard.writeText(Link)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          })
          .catch(err => console.error("Failed to copy text: ", err));
      };

    
    const handleInputChange = (e) => {
        setNewField(e.target.value);
      };
  
      const handleTypeChange = (value) => {
          setFieldType(value);
      };
    
      const handleCloseAddNewField = () => {
          setShowInput(!showInput);
      }
      const handleAddField = () => {
          if (newField && fieldType) {
              setShowInput(!showInput);
              const newFieldObject = { name: newField, type: fieldType };
              setFields((prevFields) => [...prevFields, newFieldObject]);
              setSelectedFields((prevSelected) => [...(prevSelected || []), newField]);
              setNewField('');
              setFieldType('');
          } else {
            showErrorToast("Please enter a field name and select a type.")
          
          }
      };
      
      const handleRemoveField = async (fieldName) => {
    
        try {
            const response = await api.delete(`/modules/deleteField/${name}/${fieldName}`);
            if (response.status === 200) {
                setFields((prevFields) =>
                    prevFields.filter((field) => field.name !== fieldName)
                );
            }
        } catch (err) {
            console.error('Failed to delete field:', err);
        }
    };
    useEffect(() => {

        const fetchSettingsModel = async () => {
            try {
            const response = await api.get(`/modules/${name}`); 
 
            const activeFields = response.data.settings
            .filter((field) => field.isActive) 
            .map((field) => field.name);  
            
           
            const responseFields = response.data.settings;
            setFields((prevFields) => {
                const responseFieldsMapped = responseFields.map(item => ({ name: item.name, type: item.type }));
            
                const newFields = responseFieldsMapped.filter(
                    responseField => !prevFields.some(prevField => prevField.name === responseField.name)
                );
    
                return [...prevFields, ...newFields];
            });
                setSelectedFields(activeFields);
    
            } catch (err) {
                console.log('Failed to fetch public areas'); 
            }
        };
    
        fetchSettingsModel(); 
    
        }, []);

    const handleSubmit = () => {
        setShowInput(!showInput); 
    };
    return (
        <div className='flex flex-col h-full gap-5'>
           {ifLink && Link && (
                <div className="flex flex-col gap-2">
                    <p className="text-sm text-gray-700 mb-1">URL :</p>
                    <div className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm border border-gray-300">
                    <a 
                        href={Link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-600 hover:underline truncate max-w-[80%]"
                    >
                        {Link}
                    </a>
                    <span
                        onClick={copyToClipboard}
                        className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 p-2 rounded-md cursor-pointer"
                        title="Copy"
                        style={{
                        transition: "background-color 0.3s, transform 0.3s",
                        }}
                    >
                        {copied ? (
                        <Icon name="CopyCheck" size={18} className="text-green-500" />
                        ) : (
                        <Icon name="Copy" size={18} className="text-gray-600" />
                        )}
                    </span>
                    </div>
                </div>
            )}
            <form className=" border-b border-gray-300 pb-5">
                    {fields.map((field, index) => (
                    
                        <div key={index} className="flex items-center group p-3 rounded-lg bg-gray-50 hover:bg-gray-100 mb-1">
                            <input
                                type="checkbox"
                                id={field.name}
                                checked={selectedFields.includes(field.name)}
                                onChange={() => handleCheckboxChange(field.name)}
                                className="mr-3 w-4 h-4"
                                disabled={field.isRequired}
                            />
                            <div className="flex flex-col">
                                <label htmlFor={field} className="text-gray-800 capitalize text-md font-semibold">
                                    {field.name}
                                </label>
                                {field.type === 'text' &&
                                    <p className="text-gray-400 capitalize text-sm">texte</p>
                                }
                                {field.type === 'tel' &&
                                    <p className="text-gray-400 capitalize text-sm">numéro</p>
                                }
                                {field.type === 'textarea' &&
                                    <p className="text-gray-400 capitalize text-sm">texte</p>
                                }
                                {field.type === 'checkbox' &&
                                    <p className="text-gray-400 capitalize text-sm">case à cocher</p>
                                }
                                {field.type === 'location' &&
                                    <p className="text-gray-400 capitalize text-sm">adresse</p>
                                }
                                {field.type === 'date' &&
                                    <p className="text-gray-400 capitalize text-sm">date</p>
                                }
                            </div>
                        {!field.isProtected && (
                            <button
                             type="button"
                            onClick={() => handleRemoveField(field.name)}
                            className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 flex items-center justify-center ml-auto
                            opacity-0 group-hover:opacity-100 group-hover:scale-105"
                            >
                                <Icon name="Trash2" />
                            </button>
                        )}
                        </div>
                    
                    ))}
            </form>
            {showInput ? (
                <>
                    <div className="border border-gray-300 bg-gray-100 rounded-lg p-4 flex flex-col gap-4">
                    <button
                        onClick={handleCloseAddNewField}
                            className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 text-white bg-orange hover:bg-orange rounded-full flex items-center justify-center ml-auto shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                        <Icon name="X" />
                    </button>
                    <div className="flex items-center border rounded-lg ">
                        <InputField
                            handleChange={handleInputChange}
                            value={newField}
                            type="text"
                    
                            placeholder="Enter new field name"
                        />
                    </div>
                    <Select
                        style={{ width: '100%', height: '3rem' }}
                        placeholder="Select field type"
                        options={options}
                        // value={fieldType}
                        onChange={handleTypeChange}
                        />
                    <button
                        onClick={handleAddField}
                        className="p-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto "
                        >
                        <p>{t("createBtn")}</p>

                        <Icon name="Plus" />
                    </button>
                    </div>
                </>
                
            ) : (
                <button
                    onClick={handleSubmit}
                    className="p-2 bg-orange hover:shadow-lg h-10 w-10 transition ease-in text-white rounded flex items-center justify-center ml-auto "
                    >
                    <Icon name="Plus" />
                </button>
            )}
  
        </div>
    )
}

export default SettingsClientDrawer;