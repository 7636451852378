import React, { useState } from "react";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import UserListCard from "../UserListCard";
import {
  buttonUserList,
  userListData,
} from "../../constant/constant";
export default () => {
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  
  return (
    <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelectedBtn}
          selecteBtn={selectedBtn}
          data={buttonUserList}
        />
      </div>
      <UserListCard data={userListData} />
    </div>
  );
};
