import React, { useEffect, useState } from "react";
import SelectButton from "../components/SelectButton";
import { buttonClients, clientsOption } from "../constant/constant";
import Page from "./Page";
import InputComp from "../components/InputComp";
import UserCard from "../components/UserCard";
import CustomDrawer from "../components/CustomDrawer";
import ClientDrawer from "../components/drawer/ClientDrawer";
import Icon from "../components/Icon";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import api from "../utils/api";
import { useAppContext } from "../context/Context";
import { useTranslation } from "react-i18next";
import ClientNavigator from "../components/navigators/ClientNavigator";
import { useDispatch, useSelector } from "react-redux";
import { addNewClient, setClientList } from "../redux/slices/clientSlice";
import useOrgDetail from "../hooks/useOrgDetail";
import useClients from "../hooks/useClients";
import { addLocation } from "../redux/slices/appConfigSlice";
import validateFields from "../utils/validateFields";
import { showErrorToast, showSuccessToast } from "../utils/toastConfig";
import { useNavigate } from 'react-router-dom';

const Clients = () => {

  useClients();

  const { t } = useTranslation();
  // const { clientList, setClientList } = useAppContext();
  const dispatch = useDispatch();
  const { clientList } = useSelector((state) => state.clientReducer);
  const [seletectedBtn, setSeletectedBtn] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inputData, setInputData] = useState("");
  const [selectedTab, setSelectedTab] = useState("PROFESSIONAL");
  const [filteredClients, setFilteredClients] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [Client, setClient] = useState([]);
  const navigate = useNavigate();

  const code = "Client"
  const [formData, setFormData] = useState({
    fields: [],
  });
  const [ContratFormData, setContratFormData] = useState({
    fields: [],
  });

  const [file, setFile] = useState(null);
  useOrgDetail();

  useEffect(() => {

    const fetchSettingsModel = async () => {
        try {
        const response = await api.get(`/modules/${code}`);
        if (!response.data.isActive) {
          navigate('/404'); 
          return; 
        }
        const clientFields = response.data.settings;
        const updatedFields = clientFields.filter((field) => field.isActive).map((field) => (
            {
                code : field.code,
                name : field.name,
                value : '',
            }
        ));
        setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
        }));
  
        setClient(response.data.settings)

        } catch (err) {
            console.log('Failed to fetch public areas'); 
        }
    };

    fetchSettingsModel(); 

}, []);

  const { userDetail } = useSelector((state) => state.appConfigReducer);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLocationChange = (e) => {
    setFormData({
      ...formData,
      address: e,
    });
  };
  const handelAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    const { updatedFields, isValid } = validateFields(formData.fields);

    if (!isValid) {
      setFormData({
        ...formData,
        fields: updatedFields,
      });
      return;
    }

    if (!file){
      showErrorToast("Échec de la création du client : le logo de l'entreprise est requis")
      return;
    } 
      
    const clientFormData = new FormData();
    
    const data = {
      fields: formData.fields ,
      type: selectedTab
    };
    
    clientFormData.append("data", JSON.stringify(data));
    clientFormData.append("image", file);

      try {
        const res = await api.post("/client/createClient", clientFormData);
        dispatch(addNewClient(res.data.client));
        dispatch(addLocation(res.data.location));      
        showSuccessToast("Le client a été créé avec succès")
      if (contracts.length  > 0) {
          try {
            const dataContrat = {
                contracts: contracts.map(contrat => ({
                    fields: contrat.fields, 
                })),
                id: res.data.client.clientOrgId,
            };

            const contratRes = await api.post("/contrat/creatContrats", dataContrat);
            showSuccessToast("Le contrat a été créé avec succès")
        } catch (error) {
          showErrorToast("Échec de la création du contrat. Veuillez réessayer")
            console.error("Error while sending contract data:", error);
        }
      }
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      showErrorToast("Échec de la création du client. Veuillez réessayer.")
      console.log("Error occured while creating new client", error);
    }
  };

  const filterClients = (searchValue) => {
    let filteredClients = [...clientList];
    if (!searchValue || !searchValue?.trim()) {
      setFilteredClients(filteredClients);
      return;
    }

    searchValue = searchValue?.toLowerCase();

    filteredClients = filteredClients.filter((client) => {
      if (
        client?.firstName?.toLowerCase()?.includes(searchValue) ||
        client?.lastName?.toLowerCase()?.includes(searchValue)
      ) {
        return client;
      }
    });

    setFilteredClients(filteredClients);
  };

  return (
    <>
      <Page>
        <div className="flex w-full h-[calc(100vh-25px)] gap-5">
          <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
            <div className="flex items-center justify-between h-16 ">
              <h1 className="text-[#090F4E] text-2xl font-bold leading-10">
                {t("clients")}
              </h1>
              <button
                onClick={() => setDrawerOpen(true)}
                className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
              >
                <Icon name="UserRoundPlus" />
              </button>
            </div>
            <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
              <div className="flex flex-col gap-4 w-full p-4">
                <InputComp
                  setInputData={setInputData}
                  inputData={inputData}
                  filterClients={filterClients}
                />
                <SelectButton
                  setSelecteBtn={setSeletectedBtn}
                  selecteBtn={seletectedBtn}
                  data={buttonClients}
                />
              </div>
              <UserCard clientList={clientList} />
            </div>
          </div>
          <ClientNavigator interventions={userDetail?.intervention || []} />
          {/* <div className="w-[500px] flex-1">
            <OptionSwiper options={clientsOption} />
          </div> */}
        </div>

        <CustomDrawer
          open={drawerOpen}
          width={600}
          onClose={() => setDrawerOpen(false)}
          footerEle={
            <FooterEle
              label={t("client.drawer.icon")}
              icon={"Image"}
              handleSubmit={handleSubmit}
              file={file}
              setFile={setFile}
            />
          }
          title={
            <DrawerHeader title={t("client.drawer.title")} icon={"Building2"} />
          }
        >
          <ClientDrawer
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            handleLocationChange={handleLocationChange}
            handelAntDValues={handelAntDValues}
            handleSubmit={handleSubmit}
            ContratFormData={ContratFormData}
            setContartFormData={setContratFormData}
            contracts={contracts}
            setContracts={setContracts}
            Client={Client}
            setClient={setClient}
          />
        </CustomDrawer>
      </Page>
    </>
  );
};

export default Clients;
