import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIntervention, updateIntervention } from "../../../redux/slices/interventionSlice";
import Icon from "../../Icon";
import api from "../../../utils/api";
import { DatePicker, Select } from "antd";
import moment from "moment";
import InputField from "../../../components/InputField"

const AssignedInterventionDetail = () => {
   const { selectedIntervention } = useSelector(state => state.interventionReducer);

   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [loading, setLoading] = useState(false);
   const [currentStatus, setCurrentStatus] = useState(null);
   const [file, setFile] = useState(null);
   const [fileName, setFileName] = useState("");
   const [fileNameError, setFileNameError] = useState(null);

   const StatusOptions = [
      { label: 'New', value: 'new' },
      { label: 'In Progress', value: 'in progress' },
      { label: 'Completed', value: 'completed' },
      { label: 'Cancelled', value: 'cancelled' }
  ];

  const title = selectedIntervention?.fields?.find((field) => field.code === "title");
  const Name = selectedIntervention?.fields?.find((field) => field.code === "name");
  const descreption = selectedIntervention?.fields?.find((field) => field.code === "desc");
  const Phone = selectedIntervention?.fields?.find((field) => field.code === "phone");
  const Email = selectedIntervention?.fields?.find((field) => field.code === "email");
  const adresse = selectedIntervention?.fields?.find((field) => field.code === "adresse");

   const dispatch = useDispatch();

   const updateInterventionHandler = async (body) => {
      if (!body || !selectedIntervention?._id) return;
      setLoading(true);
      try {
         const res = await api.put(`/intervention/updateIntervention/${selectedIntervention._id}`, body);
         dispatch(setSelectedIntervention(res.data));
         dispatch(updateIntervention(res.data))
      } catch (error) {
         console.log("error occurred while updating intervention", error);
      }
      setLoading(false);
   };

   const handleInputFileName = (e) => {
      setFileName(e.target.value); 
    }
   const onStartDateChangeHandler = (date) => {
      setStartDate(date);
      // const body = {
      //    startDate: date,
      // };
      // updateInterventionHandler(body);
   };

   const onEndDateChangeHandler = (date) => {
      setEndDate(date);
      // const body = {
      //    endDate: date,
      // };
      // updateInterventionHandler(body);
   };

   const handleCompletion = () => {
      const body = {
         status: "completed",
      };
      updateInterventionHandler(body);
   };

   const handleRejection = () => {
      const body = {
         status: "cancelled",
      };
      updateInterventionHandler(body);
   };

   const handleUpdate = async () => {
      if (file) {
         if (!fileName || fileName.trim() === '') {
            setFileNameError('File name cannot be empty');  
            return;  
        }
      
         const formData = new FormData();
         formData.append("image", file);
         formData.append("fileName", fileName);
         formData.append("interventionId", selectedIntervention._id);
   
         try {
            await api.post("/document", formData);
            
          } catch (error) {
            console.log(error);
          }
      }
      const body = {
         startDate: startDate,
         endDate: endDate,
         status: currentStatus,
      };
      updateInterventionHandler(body);
   };

   const handleReset = () => {
      const body = {
         status: "in progress",
      };
      updateInterventionHandler(body);
   };

   const onSelectChangeHandlerStatus = (value) => {
      setCurrentStatus(value);
   }

   const disabledStartDate = (current) => {
      return current && current < moment().startOf('day');
   };

   const disabledEndDate = (current) => {
      return current && startDate && current < startDate.startOf('day');
   };

   useEffect(() => {
      if (selectedIntervention) {
         setStartDate(selectedIntervention.startDate ? moment(selectedIntervention.startDate) : null);
         setEndDate(selectedIntervention.endDate ? moment(selectedIntervention.endDate) : null);
      }
   }, [selectedIntervention]);

   

   if (!selectedIntervention) return <></>;

   return (
      <div className="flex bg-white rounded-lg p-4 ">
         <div className="px-1 flex flex-col gap-4  w-1/2 ">
            <div className="flex items-center gap-4">
               <div><Icon name={"ShieldAlert"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.criticality}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"BookType"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                    {selectedIntervention.isPublicArea ? title?.value:  selectedIntervention?.title}

                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"Quote"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                  {selectedIntervention.isPublicArea ? descreption?.value:  selectedIntervention?.description}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
                  <div><Icon name={"Phone"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        {Phone?.value}
                     </h4>
                  </div>
               </div>
               <div className="flex items-center gap-4">
                  <div><Icon name={"Mail"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        {Email?.value}
                     </h4>
                  </div>
               </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"MapPin"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                        {adresse?.value.label}
                  </h4>
               </div>
            </div>
            <div className="flex flex-col gap-4">
               {
                  selectedIntervention?.intervenant &&
                  <div className="flex gap-4">
                     <div><Icon name={"User"} color="gray" /></div>
                     <div>
                        <h4 className="text-slate-700 font-bold text-[14px] ">
                           {`${selectedIntervention?.intervenant?.firstName} ${selectedIntervention?.intervenant?.lastName}`}
                        </h4>
                     </div>
                  </div>
               }
               </div>
               {
                  selectedIntervention?.isPublicArea &&
                  selectedIntervention?.fields?.length >= 6 &&
                  selectedIntervention.fields.slice(6).map((field, index) => {
                     return (
                        <div key={index} className="mb-2">
                           <h4 className="text-slate-700 font-bold text-[14px]">
                           {field.name}
                           </h4>
                           <p className="text-slate-500 text-[12px]">
                           {field.value}
                           </p>
                      </div>
                      );
                     })
                  }
            </div>
               <div className="flex flex-col p-3 bg-white  w-1/2 border-l border-l-gray-300 pl-5 justify-between">
                  <div className="flex flex-col gap-4 justify-between">
                     <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                           <h4 className="text-slate-700 font-bold text-[14px]">Start Date :</h4>
                           <DatePicker
                                 style={{
                                 width: '300px',
                                 height: "2rem"
                              }}
                              placeholder="Start Date"
                              disabled={loading}
                              value={startDate}
                              onChange={onStartDateChangeHandler}
                              disabledDate={disabledStartDate}
                           />
                        </div>
                        <div className="flex flex-col gap-2">
                           <h4 className="text-slate-700 font-bold text-[14px]">End Date :</h4>

                           <DatePicker
                              style={{
                                 width: '300px',
                                 height: "2rem"
                              }}
                              placeholder="End Date"
                              disabled={loading || !startDate}
                              value={endDate}
                              onChange={onEndDateChangeHandler}
                              disabledDate={disabledEndDate}
                           />
                        </div>

                        <div className="flex flex-col gap-2">
                           <h4 className="text-slate-700 font-bold text-[14px]">Status :</h4>
                           <Select
                              style={{
                                 width: '300px',
                                 height: "2rem"
                              }}
                              placeholder="Status"
                              options={StatusOptions}
                              value={currentStatus}
                              onChange={onSelectChangeHandlerStatus}
                              />
                        </div>
                        {
                           file &&
                           <div className="flex flex-col gap-2">
                              <h4 className="text-slate-700 font-bold text-[14px]">Name of File :</h4>
                              <input 
                                style={{
                                    width: '300px',
                                    height: "2rem"
                                 }}
                                 type="text"
                                 placeholder="Name of File"
                                 className="border border-gray-300  rounded-lg p-2"
                                 onChange={handleInputFileName}
                                 value={fileName}
                              />
                              {fileNameError && (
                                 <div style={{ color: 'red', fontSize: '12px' }}>
                                    {fileNameError}
                                 </div>
                              )}
                           </div>
                        }
                     </div>
                     <div className="flex justify-between items-center gap-4 " >
                        <div className=" flex gap-3 items-center">
                           <label className="cursor-pointer" htmlFor="fileInput"><Icon name="FileUp" /></label>
                           <input onChange={(e) => setFile(e.target.files[0])} className='hidden' type="file" id="fileInput" />
                           <div className='flex gap-3 relative'>
                              upload file
                           </div>

                        </div>
                        <button
                           className="border p-2 rounded-lg  bg-[#F87B1B]/70 hover:bg-[#F87B1B]/90 text-white self-end"
                           disabled={loading}
                           onClick={handleUpdate}
                        >
                           Update
                        </button>
                     </div>
                     {/* {
                        selectedIntervention?.status === "in progress" &&
                        <div className="flex justify-end items-center gap-4 " >
                        <button
                           className="py-1 px-2 bg-[#EF392A]  text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleRejection}
                        >
                           Rejected
                           <Icon name={"ThumbsDown"} size={15} color={"white"} />
                        </button>
                        <button
                           className="py-1 px-2 bg-[#42A646] text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleCompletion}
                        >
                           Completed
                           <Icon name={"ThumbsUp"} size={15} color={"white"} />
                        </button>
                     </div>
                     }
                     {
                        selectedIntervention?.status !== "in progress" &&
                        <div className="flex justify-end items-center gap-4">
                        <button
                           className="py-1 px-2 bg-black text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleReset}
                        >
                           Reset
                           <Icon name={"RotateCcw"} size={15} color={"white"} />
                        </button>
                     </div>
                     } */}
                  </div>
         </div>
      </div>
   );
};

export default AssignedInterventionDetail;