import React, { useRef, useState } from 'react';

const InputField = ({ placeholder, type, name, handleChange, value, error, disabled }) => {
  // think of possible way to animate lable without using states in this comp
  // label animation
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
      setFocused(false);
  };
  
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <div className='w-full '>
      <div
        className={`relative px-2 rounded-lg border w-full ${
          focused
            ? 'border-dark border-2'
            : 'border-gray-300'
        }
        
        `}
        onClick={handleClick}
      >
        {type === 'textarea' ? (
          <textarea
            className='py-3 outline-none w-full'
            ref={inputRef}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name={name}
            value={value}
            type={type}
            disabled={disabled}
            id=''
            cols='30'
            rows='3'
          />
        ) : (
          <input
            ref={inputRef}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name={name}
            value={value}
            type={type}
            disabled={disabled}
            className='py-3 outline-none w-full'
          />
        )}
        <div
          className={`absolute left-3 z-1 transition-all ${focused ? "text-dark font-medium" : "text-gray-500"} ${
            focused || value
              ? '-top-3 bg-white text-sm  px-1'
              : 'top-3 text-base '
          }`}
        >
          {placeholder}
        </div>
      </div>
      {error?.message && <p className="text-red-500 px-2 text-[12px]">{error?.message}</p>}
    </div>
  );
};

export default InputField;
