import React from "react"; 
import { useState } from "react";
import { Tag } from "antd";
import Icon from '../Icon';
import interventionStatusTagColor from '../../utils/interventionStatusTagColor';



const InterventionDisplay = ({ interventions }) => {
    const [selectedIntervention, setSelectedIntervention] = useState(null);

    const handleInterventionClick = (intervention) => {
      setSelectedIntervention(intervention);

    };
    
    // const Title = intervention.fields?.find((field) => field.code === "title");
    // const Name = intervention.fields?.find((field) => field.code === "name");
    // const Descreption = intervention.fields?.find((field) => field.code === "desc");
    // const Phone = intervention.fields?.find((field) => field.code === "phone");
    // const Email = intervention.fields?.find((field) => field.code === "email");
    // const Adresse = intervention.fields?.find((field) => field.code === "adresse");

    function convertDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        
        return `${day}/${month}/${year}`;
    }
      

    const renderFields = (fields) => {
        return fields?.map((field, index) => (
          <div key={index} className="p-3">
            <h4 className="text-slate-700 font-bold text-[14px] capitalize">{field.name}</h4>
            <p className="text-slate-500 text-[12px]">{field.value}</p>
          </div>
        ));
    };
    
      if (interventions.length === 1 || selectedIntervention) {
        const interventionToDisplay = selectedIntervention || interventions[0];
        const Title = interventionToDisplay.fields?.find((field) => field.code === "title");
        const Name = interventionToDisplay.fields?.find((field) => field.code === "name");
        const Descreption = interventionToDisplay.fields?.find((field) => field.code === "desc");
        const Phone = interventionToDisplay.fields?.find((field) => field.code === "phone");
        const Email = interventionToDisplay.fields?.find((field) => field.code === "email");
        const Adresse = interventionToDisplay.fields?.find((field) => field.code === "adresse");
        return (
          <>

            <div className=" relative space-y-4 h-full">
              <div className="flex items-center gap-3 p-3">
                <h3 className="text-[16px] font-bold text-gray-700">Status: </h3>
                <Tag color={interventionStatusTagColor(interventionToDisplay.status)}>
                  {interventionToDisplay.status}
                </Tag>
              </div>
    
              {interventionToDisplay.startDate && (
                <div className="flex items-center gap-3 p-3">
                  <Icon name="Calendar" color="gray" size={30} />
                  <div>
                    <h4 className="text-slate-700 font-bold text-[14px]">
                      Start: {convertDate(interventionToDisplay.startDate)}
                    </h4>
                  </div>
                </div>
              )}
    
              {interventionToDisplay.endDate && (
                <div className="flex items-center gap-3 p-3">
                  <Icon name="Calendar" color="gray" size={30} />
                  <div>
                    <h4 className="text-slate-700 font-bold text-[14px]">
                      End: {convertDate(interventionToDisplay.endDate)}
                    </h4>
                  </div>
                </div>
              )}
    
              <div className="flex items-center gap-4 ">
                <div className="p-2 bg-gray-300 rounded-full">
                  <Icon name="ShieldAlert" color="gray" size={20} />
                </div>
                <div>
                  <h4 className="text-slate-700 font-bold text-[14px]">
                    {interventionToDisplay.criticality}
                  </h4>
                </div>
              </div>
              <div className="flex items-center gap-4">
               <div><Icon name={"BookType"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">

                    {Title?.value}

                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"Quote"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                    {Descreption?.value}

                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
                  <div><Icon name={"Phone"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        {Phone?.value}
                     </h4>
                  </div>
               </div>
               <div className="flex items-center gap-4">
                  <div><Icon name={"Mail"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        {Email?.value}
                     </h4>
                  </div>
               </div>
               {
                  interventionToDisplay?.isPublicArea &&
                  interventionToDisplay?.fields?.length >= 6 &&
                  interventionToDisplay.fields.slice(6).map((field, index) => {
                     return (
                        <div key={index} className="mb-2">
                           <h4 className="text-slate-700 font-bold text-[14px]">
                           {field.name}
                           </h4>
                           <p className="text-slate-500 text-[12px]">
                           {field.value}
                           </p>
                      </div>
                      );
                     })
                }
            {selectedIntervention && (
              <button
                onClick={() => setSelectedIntervention(null)}
                className="flex text-gray-500 gap-2  mb-4 absolute bottom-0 right-0 "
              >
                <Icon name="CircleArrowLeft" color="gray" />
                <p>Back to list</p>
              </button>
            )}
            </div>
    
          </>
        );
      }
    
      return (
        <div className="space-y-3">
          {interventions.map((intervention, index) => {
            const Title = intervention.fields?.find((field) => field.code === "title");
            const Name = intervention.fields?.find((field) => field.code === "name");
            const Description = intervention.fields?.find((field) => field.code === "desc");
      
            return (
              <div
                key={index}
                className="flex transition ease-in cursor-pointer relative w-full items-center px-4 py-8 border-b border-gray-200 hover:bg-gray-100 justify-between"
                onClick={() => handleInterventionClick(intervention)}
              >
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <h3 className="text-slate-700 font-bold">
                      {Title?.value || "Untitled"} #{index + 1}
                    </h3>
                    <p>{Description?.value || "No description available"}</p>
                    <p>{Name?.value || "Unknown"}</p>
                  </div>
                </div>
                <div className="absolute top-4 right-2">
                  <Tag color={interventionStatusTagColor(intervention?.status)}>
                    {intervention?.status}
                  </Tag>
                </div>
              </div>
            );
          })}
        </div>
      );
    };
  
  export default InterventionDisplay;