import React from "react"

const InterventionsDashboard = () => {
    return (
        <div className="w-full h-full flex flex-col rounded-lg overflow-auto gap-3">
            <div className="flex w-full h-[80px] gap-5 rounded-lg ">
                <div className="w-1/4 bg-white shadow rounded-lg ">

                </div>
                <div className="w-1/4 bg-white shadow rounded-lg ">

                </div>
                <div className="w-1/4 bg-white shadow rounded-lg ">

                </div>
                <div className="w-1/4 bg-white shadow rounded-lg ">

                </div>
            </div>
            <div className="w-full h-auto rounded-lg grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    <div className="flex items-center justify-between">
                        <p className="text-[#090F4E] text-[15px]  font-medium">interventions par métier</p>
                    </div>
                    <div className="border border-red-500 h-full"></div>
                </div>
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    
                </div>
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    
                </div>
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    
                </div>
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    
                </div>
                <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow h-[14rem] p-5">
                    
                </div>
            </div>
        </div>
    )
}

export default InterventionsDashboard