import React, { useState } from "react";
import OptionSwiper from "../OptionSwiper";
import {
  interventionOptions,
  interventionsData,

} from "../../constant/constant";
import { useAppContext } from "../../context/Context";
import InterventionDetail from "./InterventionDetail";
import InterventionDocument from "./InterventionDocument";

export default () => {
  const [activeTab, setActiveTab] = useState("details");
  return (
    <div className="w-[500px] flex flex-col flex-1 gap-2">
 
      <OptionSwiper
        options={interventionOptions}
        {...{ activeTab, setActiveTab }}
      />
      <div className="overflow-auto">
        <RenderTabs activeTab={activeTab} />
      </div>
    </div>
  );
};

const RenderTabs = ({ activeTab }) => {
  const { itinterventionId } = useAppContext();

  const filteredData = interventionsData.filter(
    (item) => item.id == itinterventionId
  );

  switch (activeTab) {
    case "details":
      return (
        <InterventionDetail />
      );
    case "documents":
      return (
          <InterventionDocument />
      );
  }
};
