import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Edit } from 'lucide-react'

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    slogan: "Create, Assign, Communicate in one click",
                    sloganSubtitle: "Whether you manage technicians or service providers on a daily basis, do it now to the fullest with a tool specifically designed for this!",
                    login: {
                        title: "Welcome Back!",
                        subtitle: "Sign in to your account below",
                        signUpMessage: "Don't have an account? ", 
                        signUp: "Sign up", 
                        login: "Login",
                        email: "Email",
                        password: "Password",
                    },
                    signup: {
                        title: "Create Account",
                        subtitle: "Create your account to access a world of features.",
                        loginMessage: "Already have an account? ",
                        login: "Login",
                        signUp: "Sign up", 
                        confirmPassword: "Confirm Password",
                        name: "Full name",
                        email: "Email",
                        password: "Password",
                    },
                    // models
                    clients: "Clients", 
                    intervenants_one: "Intervenant", 
                    intervenants_other: "Intervenants", 
                    serviceProvider: "Service Provider",
                    technicians_one: "Technician", 
                    technicians_other: "Technicians", 
                    locations_one: "Location", 
                    locations_other: "Locations", 
                    interventions: "Interventions", 
                    maintenances: "Maintenances", 
                    equipements: "Equipments", 
                    documents: "Documents", 
                    automations: "Automations", 
                    contracts: "Contracts", 
                    users: "Users", 
                    params: "Params", 
                    // tabs
                    projectManagers: "Project Managers", 
                    automationsTab: "Automations", 
                    contractsTab: "Contracts", 
                    // fields
                    search: "Search", 
                    category: "Category", 
                    type: "Type", 
                    name: "Name", 
                    lastName: "Last name", 
                    address: "Address", 
                    emailField: "Email", 
                    phoneNumber: "Phone number", 
                    interventionAddress: "Intervention address", 
                    // btns
                    createBtn: "Create", 
                    showMoreBtn: "Show more", 
                    //new btns
                    saveBtn : "Save",
                    EditBtn : "Edit",
                    // labels
                    professionalLabel: "Professional", 
                    individualLabel: "Individual", 
                    // nested
                    client: {
                        drawer: {
                            title: "Create new client", 
                            client: "Client", 
                            icon: "Client logo" 
                        }
                    },
                    location: {
                        title: "Create new location", 
                        coverImage: "Cover image", 
                    },
                    intervenant: {
                        title: "Create new intervenant", 
                        companyName: "Company name",
                        coverImage: "intervenant cover", 
                    },
                    workOrder: {
                        title: "New intervention", 
                    }
                }
            },
            fr: {
                translation: {
                    slogan: "Créez, Attribuez, Communiquez en un clic",
                    sloganSubtitle: "Que vous gériez au quotidien des techniciens ou des prestataires, faites le désormais à fond avec un outil pensé spécifiquement pour cela !",
                    login: {
                        title: "Bon retour!",
                        subtitle: "Connectez-vous à votre compte ci-dessous",
                        signUpMessage: "Vous n'avez pas de compte? ", // added
                        signUp: "S'inscrire", // added
                        login: "Connexion", // added
                        email: "Email", // added
                        password: "Mot de passe", // added
                    },
                    signup: {
                        title: "Créer un compte",
                        subtitle: "Créez votre compte pour accéder à un monde de fonctionnalités.",
                        loginMessage: "Vous avez déjà un compte? ", // added
                        login: "Connexion", // added
                        signUp: "S'inscrire", // added
                        confirmPassword: "Confirmez le mot de passe", // added
                        name: "Nom complet", // added
                        email: "Email", // added
                        password: "Mot de passe", // added
                    },
                    // models
                    clients: "Clients", 
                    technicians_one: "Technicien", 
                    technicians_other: "Techniciens", 
                    intervenants_one: "Intervenant", 
                    intervenants_other: "Intervenants", 
                    serviceProvider: "Prestataire",
                    locations_one: "Lieu", 
                    locations_other: "Lieux", 
                    interventions: "Interventions", 
                    maintenances: "Maintenances", 
                    equipements: "Équipements", 
                    documents: "Documents", 
                    automations: "Automatisations", 
                    contracts: "Contrats", 
                    users: "Utilisateurs", 
                    params: "Paramètres", 
                    // tabs
                    projectManagers: "Chargés d'affaires", 
                    automationsTab: "Automatisations", 
                    contractsTab: "Contrats", 
                    // fields
                    search: "Rechercher", 
                    category: "Catégorie", 
                    type: "Type", 
                    name: "Nom", 
                    lastName: "Nom de famille", 
                    address: "Adresse", 
                    emailField: "Email", 
                    phoneNumber: "Numéro de téléphone", 
                    interventionAddress: "Adresse d'intervention", 
                    // btns
                    createBtn: "Créer", 
                    showMoreBtn: "Afficher plus", 
                    // labels
                    professionalLabel: "Professionnel", 
                    individualLabel: "Particulier", 
                    // nested
                    client: {
                        drawer: {
                            title: "Créer un nouveau client", 
                            client: "Client", 
                            icon: "Logo de l'entreprise" 
                        }
                    },
                    location: {
                        title: "Création d'un lieu", 
                        coverImage: "Image de couverture", // added
                    },
                    intervenant: {
                        title: "Créer un intervenant", 
                        companyName: "Nom de l'entreprise",
                        coverImage: "intervenant cover", 
                    },
                    workOrder: {
                        title: "Nouvelle intervention", 
                    }
                }
            }
        }
        
    })

    // {t("")}