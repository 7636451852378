import React from "react";
import { useState } from "react";
import Icon from "../components/Icon";
import DepensesDashboard from '../components/dashboard/DepensesDashboard';
import ConsommationsDashboard from '../components/dashboard/ConsommationsDashboard';
import EquipmentsDashboard from '../components/dashboard/EquipmentsDashboard';
import IntervenantsDashboard from '../components/dashboard/IntervenantsDashboard';
import InterventionsDashboard from '../components/dashboard/InterventionsDashboard';
import PerformanceDashboard from '../components/dashboard/PerformanceDashboard';


const Dashboard = () => {
    const [seleTab, setSelectedTab] =  useState("interventions")
    const tabs = [
        { name: 'interventions', icon: 'Newspaper' },
        { name: 'performance', icon: 'UserRound' },
        { name: 'dépenses', icon: 'Euro' },
        { name: 'intervenants', icon: 'UserRound' },
        { name: 'Equipments', icon: 'UserRound' },
        { name: 'Consommations', icon: 'UserRound' },
      ];

    const handleClick = (item) => {
        setSelectedTab(item);
      };
    
      const renderPage = () => {
        switch (seleTab) {
          case 'interventions':
            return <InterventionsDashboard />;
          case 'performance':
            return <PerformanceDashboard />;
          case 'dépenses':
            return <DepensesDashboard />;
          case 'intervenants':
            return <IntervenantsDashboard />;
          case 'Equipments':
            return <EquipmentsDashboard />;
          case 'Consommations':
            return <ConsommationsDashboard />;
          default:
            return null;
        }
      };
    return (
        <div className="flex flex-col w-full h-[calc(100vh-25px)] p-5 gap-3">
            <div className="bg-white shadow w-full h-[50px] rounded-lg  flex  flex justify-around items-center px-4">
                {tabs.map((item) => (
                    <div
                        key={item}
                        onClick={() => handleClick(item.name)}
                        className={`cursor-pointer text-blue-950 flex items-center gap-2 flex-grow justify-center p-3   ${
                        seleTab === item.name ? 'border-b-4 border-blue-950' : ''
                        }`}
                    >
                        <Icon name={`${item.icon}`} />
                        {item.name}
                    </div>
                ))}
            </div>
            <div className=" w-full h-full rounded-lg overflow-auto">
                {renderPage()}
            </div>
        </div>
    )
}

export default Dashboard