import React, { useEffect, useState } from 'react'
import { tabsClientPreview } from '../../constant/constant'
import InputField from '../InputField'
import Icon from '../Icon'
import api from "../../utils/api";
import PhoneInputField from '../PhoneInput'
import './style.css'
import DrawerTabs from './DrawerTabs'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from "react-i18next";
import ContartInput from '../clients/ContartInput';
import ContartInputEdit from '../clients/ContartInputEdit';
import { Contact } from 'lucide-react';
import DynamicInputs from '../DynamicInputs';
import validateFields from '../../utils/validateFields';

const ClientDrawer = ({ setSelectedTab, selectedTab, formData,setFormData, handleChange, handelAntDValues, handleSubmit, handleLocationChange 
    ,ContratFormData, setContartFormData, contracts, setContracts, Client, setClient
}) => {
    const { t } = useTranslation();
    const [isContrat, setIsContrat] = useState(false);
    const name = "Client"
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentEditIndex, setCurrentEditIndex] = useState(null); 
    const [editFormData, setEditFormData] = useState({});




    const handleChangeInput = (e, index) => {
        const { name, value, type, checked } = e.target;
      
        const fieldValue = type === "checkbox" ? checked : value;
      
        if (formData.fields[index].type === 'location') {
          const updatedFields = [...formData.fields];
          updatedFields[index] = {
            code: formData.fields[index]?.code,
            name: formData.fields[index]?.name,
            type: formData.fields[index]?.type,
            value: value ? value.label : "",  
          };
          setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
          }));
        } else {
      
          const updatedFields = [...formData.fields];
          updatedFields[index] = {
            code: formData.fields[index]?.code,
            name: formData.fields[index]?.name,
            type: formData.fields[index]?.type,
            value: fieldValue,
          };
          setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
          }));
        }
      };
      
      const handleAddContrat= () => {
        const { updatedFields, isValid } = validateFields(ContratFormData.fields);

        if (!isValid) {
            setContartFormData({
            ...ContratFormData,
            fields: updatedFields,
            });
            return;
        }

        setContracts([...contracts, ContratFormData]);

        setIsContrat(false);
    };


    const handleDelete = (index) => {
        setContracts(contracts.filter((_, i) => i !== index));
    };

    const handleEditClick = (index) => {
        setCurrentEditIndex(index);
        setEditFormData(contracts[index]);
        setIsEditModalOpen(true)
    };
      
      const handleSaveEdit = () => {
        const updatedContracts = [...contracts];
        updatedContracts[currentEditIndex] = editFormData; 
        setContracts(updatedContracts); 
        setIsEditModalOpen(false); 
      };

      function convertDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        
        return `${day}/${month}/${year}`;
      }
    return (
        <div className='flex flex-col gap-5'>
            <>
                <DrawerTabs data={tabsClientPreview} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                <form onSubmit={handleSubmit} className='flex flex-col gap-7'>
                    <DynamicInputs data={Client} formData={formData} handleChangeInput={handleChangeInput} />
                </form>
            </>
                <div className='border-t border-gray-300 pt-5'>
                {contracts.map((contract, index) => {
      
                        const titleField = contract.fields.find(field => field.code === "title");

                    return (
                    <div
                        key={index}
                        className="flex items-center justify-between group p-3 rounded-lg bg-gray-100 hover:bg-gray-200 mb-1"
                    >
                        <div className='flex  gap-5'>
                            <p className=''>{titleField?.value || "No Title"}</p>
                      
                     
                    
                        </div>
                        <div className='flex'> 

                            <button
                            onClick={() => handleEditClick(index)}
                            type="button"
                            className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 flex items-center justify-center opacity-0 group-hover:opacity-100 group-hover:scale-105"
                            >
                            <Icon className="hover:scale-105" name="Pencil" />
                            </button>
                            <button
                            onClick={() => handleDelete(index)}
                            type="button"
                            className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 flex items-center justify-center ml-auto opacity-0 group-hover:opacity-100 group-hover:scale-105"
                            >
                            <Icon className="hover:scale-105" name="Trash2" />
                            </button>
                        </div>
                    </div>
                    );
                })}
                </div>
            <div className="flex justify-end ">
    
                <button
                    onClick={() => setIsContrat(!isContrat)}
                    className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto "
                >
                <p>{contracts.length === 0 ? 'Ajouter un contrat' : 'Ajouter un autre contrat'}</p>
                <Icon name="Pencil" size={18} />
                </button>

            </div>
            {isContrat &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
                    <div className="flex flex-col w-full max-w-md gap-3 px-4 py-8 border-b border-gray-200 rounded-lg bg-white">
                        <button
                            onClick={() => setIsContrat(false)}
                            className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 text-white bg-orange hover:bg-orange rounded-full flex items-center justify-center ml-auto shadow-lg"
                            >
                            <Icon name="X" />
                        </button>
                            <ContartInput formData={ContratFormData} setFormData={setContartFormData} />
                        <button
                            onClick={handleAddContrat}
                            className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto"
                        >
                            <p> {t("saveBtn")}</p>
                            <Icon name="Pencil" size={18} />
                        </button>
                    </div>
                </div>
            }

            {isEditModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
                    <div className="flex flex-col w-full max-w-md gap-3 px-4 py-8 border-b border-gray-200 rounded-lg bg-white">
                    <button
                        onClick={() => setIsEditModalOpen(false)}
                        className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 text-white bg-orange hover:bg-orange rounded-full flex items-center justify-center ml-auto shadow-lg"
                    >
                        <Icon name="X" />
                    </button>
                    <ContartInputEdit
                        formData={editFormData}
                        setFormData={setEditFormData}
                    />
                    <button
                        onClick={handleSaveEdit}
                        className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto"
                    >
                        <p>Save Changes</p>
                        <Icon name="Check" size={18} />
                    </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ClientDrawer