import InputField from '../InputField';
import { Select } from 'antd';
import Icon from '../Icon';
import PhoneInputField from '../PhoneInput';
import DrawerTabs from './DrawerTabs';
import { intervenantTabs } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
const IntervenantsDrawer = ({ formData, handleChange, handeAntDValues, selectedTab, setSelectedTab, locations, edit }) => {
  const { t } = useTranslation();
  return (
    <div>
      {
        !edit &&
        <DrawerTabs data={intervenantTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      }
      <div className='flex flex-col gap-5 mt-4'>
        {
          ((edit && formData?.enterprise) || (!edit && selectedTab === "SERVICE_PROVIDER")) &&
            <InputField
              placeholder={t("intervenant.companyName")}
              handleChange={handleChange}
              name={'enterprise'}
              type={'text'}
              value={formData.enterprise}
            />
          }
        {
          selectedTab != "SERVICE_PROVIDER" &&
            <div className='flex gap-5'>
              <InputField
                placeholder={'Prenom'}
                handleChange={handleChange}
                name={'firstName'}
                type={'text'}
                value={formData.firstName}
              />
              <InputField
                placeholder={'Nom'}
                handleChange={handleChange}
                name={'name'}
                type={'text'}
                value={formData.name}
              />
            </div>
        }
        <InputField
          placeholder={'email'}
          handleChange={handleChange}
          name={'email'}
          type={'text'}
          value={formData.email}
        />

        <div className='flex gap-5'>
          <PhoneInputField
            handleChange={handeAntDValues}
            value={formData.cellNumber}
            name={'cellNumber'}
            placeholder={'Numéro de portable'}
          />
          <PhoneInputField
            handleChange={handeAntDValues}
            value={formData.landlineNumber}
            name={'landlineNumber'}
            placeholder={'Numéro de fixe'}
          />
        </div>
        <InputField
          placeholder={'Métiers'}
          handleChange={handleChange}
          name={'professions'}
          type={'text'}
          value={formData.professions}
        />
        {/* <InputField
          placeholder={"Zone(s) d'intervention"}
          handleChange={handleChange}
          name={'zone'}
          type={'text'}
          value={formData.zone}
        /> */}
        <Select
          mode='tags'
          style={{
            width: '100%',
            height: '3rem',
          }}
          placeholder="Zone(s) d'intervention"
          options={locations}
          value={formData.zone}
          onChange={(value) => handeAntDValues(value, 'zone')}
        />
        <div className='flex justify-between items-center'>
          
        <div className='w-full bg-[#062a52] py-3 px-5 flex flex-col gap-3 rounded-lg linearBG'>
                <div className='flex items-center text-white gap-2'>
                  <p >Activer l'interface</p> <p className='organo text-orange'>Planigramme</p>
                </div>
                <div className='text-gray-300 '>
                  L'interface permettra à votre {t(selectedTab === "SERVICE_PROVIDER" ? "serviceProvider" : "technicians_one")} de gérer les
                  interventions et de visualiser l'ensemble de la bases de
                  données (client, lieux, équipements, tickets, etc.). Vous pouvez
                  élargir ou restreindre les droits de votre {t(selectedTab === "SERVICE_PROVIDER" ? "serviceProvider" : "technicians_one")} depuis
                  l'onglet gestion de mon compte.
                </div>
                <div className='py-2 w-full items-center px-3 gap-3 bg-[#264467] rounded-lg text-white flex justify-center glassy'>
                  <div className='flex items-center gap-4'>
                    <Icon size={20} name={'Mail'} />
                    <p>Un email sera envoyé à votre {t(selectedTab === "SERVICE_PROVIDER" ? "serviceProvider" : "technicians_one")} pour activer son interface</p>
                  </div>
                 
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default IntervenantsDrawer;
