import React from "react";
import { useState, useEffect } from "react";
import api from "../../utils/api";
import InputField from "../InputField";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import moment from "moment";
import { DatePicker, Select } from "antd";


const ContartInput = ({formData, setFormData}) => {
    const name = "Contrat"
    const [Contrat, setContrat] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { t } = useTranslation();



   const disabledStartDate = (current) => {
    return current && current < moment().startOf('day');
 };

   const disabledEndDate = (current) => {
    return current && startDate && current < startDate.startOf('day');
  };

    useEffect(() => {
        const fetchSettingsModel = async () => {
            try {
            const response = await api.get(`/modules/${name}`);
            const contratFields = response.data.settings;
            const updatedFields = contratFields.filter((field) => field.isActive).map((field) => (
                {
                    
                    code : field.code,
                    name : field.name,
                    type : field.type,
                    value : '',
                }
            ));
            setFormData((prevData) => ({
                ...prevData,
                fields: updatedFields,
            }));
            setContrat(response.data.settings);
            } catch (err) {
                console.log('Failed to fetch public areas'); 
            }
        };
        fetchSettingsModel(); 
  }, []);

    const handleChangeInput = (e, index, fiealdType = null ,dateType = null) => {
        const { name, value, type, checked } = e.target;
      
        const fieldValue = type === "checkbox" ? checked : value;
        

        if (formData.fields[index].type === 'location') {
          const updatedFields = [...formData.fields];
          updatedFields[index] = {
            code: formData.fields[index]?.code,
            name: formData.fields[index]?.name,
            type: formData.fields[index]?.type,
            value: value ? value.label : "",  
          };
          setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
          }));
        }
        else if (fiealdType === "date") {
          const updatedFields = [...formData.fields];
      
          if (dateType === "startDate") {
            setStartDate(value);
            updatedFields[index] = {
              code: formData.fields[index]?.code,
              name: formData.fields[index]?.name,
              type: formData.fields[index]?.type,
              value: value,
            };
          } else if (dateType === "endDate") {
            setEndDate(value);
            updatedFields[index] = {
              code: formData.fields[index]?.code,
              name: formData.fields[index]?.name,
              type: formData.fields[index]?.type,
              value: value,
            };
          }
      
          setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
          }));
        } 
        else {
          const updatedFields = [...formData.fields];
          updatedFields[index] = {
            code: formData.fields[index]?.code,
            name: formData.fields[index]?.name,
            type: formData.fields[index]?.type,
            value: fieldValue,
          };
          setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
          }));
        }
      };

  
    return (
        <div className="flex flex-col gap-5">
            {Contrat.map((field, index) => (
                field.isActive && (

                    <div key={index}>
                    {field.type === 'text' && (
                        <>
                      <InputField
                        handleChange={(e) => handleChangeInput(e, index)} 
                        value={formData.fields[index]?.value || ''} 
                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                      />
                        {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )}
                  {field.type === 'tel' && (
                      <>
                      <InputField
                          handleChange={(e) => handleChangeInput(e, index)} 
                          value={formData.fields[index]?.value || ''} 
                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                        />
                {formData.fields[index]?.error && (
                            <div style={{ color: 'red', fontSize: '12px' }}>
                              {formData.fields[index].error}
                            </div>
                          )}
                    </>
                  )}
                  
                  {field.type === 'textarea' && (
                      <>
                      <InputField
                          handleChange={(e) => handleChangeInput(e, index)} 
                          value={formData.fields[index]?.value || ''} 
                    
                          type={field.type}
                          name={field.name}
                          placeholder={field.name}
                          />
          {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )} 
                    {field.type === 'date' && (
                      <>
                
                        {field.code === 'startDate' &&
                          <div className="flex flex-col gap-2">
            
                           <DatePicker
                                 style={{
                                   height: "3rem"
                                  }}
                                  placeholder="Start Date"
                                  value={startDate || null} 
                                  onChange={(value) =>
                                    handleChangeInput({ target: { value } }, index,"date", "startDate")
                                  }
                                  disabledDate={disabledStartDate}
                              />
                          </div>
                          }
                          {field.code === 'endDate' &&
                            <div className="flex flex-col gap-2 ">
                              <DatePicker
                                  style={{
                                    
                                    height: "3rem"
                                  }}
                                  placeholder="End Date"
                                  value={endDate || null} 
                                  disabled={!startDate} 
                                  onChange={(value) =>
                                    handleChangeInput({ target: { value } }, index,"date", "endDate")
                                  }
                                  disabledDate={disabledEndDate}
                                  />
                            </div>
                          }
                    
                      {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )} 
                  {field.type === 'checkbox' && (
                      <div key={field} className="flex items-center">
                    <input
                              type="checkbox"
                              id={field.name}
                              className="mr-2"
                              />
                          <label htmlFor={field} className="text-gray-800 capitalize">
                              {field.name}
                          </label>
                        </div>
                  )} 
                   {field.type === 'location' && (
                       <div className='relative'>
      
                        <div className={`absolute left-3 transition-all -top-3 bg-white text-sm  px-1 text-dark font-medium z-10 `} >
                            {t("address")}
                        </div>
                        <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY} 
                            selectProps={{
                                onChange: (value) => handleChangeInput({ target: { name: field.name, value: value } }, index,"location", null),  
                            styles: {
                                input: (provided) => ({
                                  ...provided,
                                  margin: '8px',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    margin: '8px',
                                }),
                                option: (provided) => ({
                                ...provided,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                margin: '8px',
                            }),
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '8px',
                                }),
                            },
                        }}
                        />
                             {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                     </div>
                  )} 
                </div>
        
                )
            ))}
        </div>
    )
}

export default ContartInput;