import react from "react";
import Icon from './Icon'


const Card = ({ children, title, item, icon, className="",handleOpenDraw , isSetting ,handleModuleActive, isActive}) => {

  return (
    <div className={`bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow ${className}`}>
      {(icon || title) && (
          <div className="flex items-center justify-between">
            {icon ? <div className={"pr-2"}><Icon name={icon} size="18" color="#090F4E" /></div> : undefined}
            {title ? (
              <label
                htmlFor=""
                className="text-[#090F4E] text-[18px]  font-medium"
              >
                {title}
              </label>
            ) : undefined}
            <div className="flex items-center">
              {isSetting && (
                  <div className="ml-auto cursor-pointer transform hover:scale-105 transition-transform duration-20" onClick={handleOpenDraw}>
                    <div className={"pr-2"}><Icon name="Settings" size="16" color="#090F4E" /></div>
                  </div>
                )
              }
              <div className="ml-auto">
              
              <input checked={isActive} onChange={handleModuleActive}  type="checkbox" />
              </div>
            </div>
          </div>
        )}
     
      {children}
    </div>
  );
};

export default Card;
