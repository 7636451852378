import React, { useState } from "react";
import {
  buttonInterventions,
  interventionsData,
} from "../../constant/constant";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import InterventionsData from "../interventionsData";

export default ({ interventions }) => {
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  const [checkedButtons, setCheckedButtons] = useState([]);

  return (
    <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
     
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelectedBtn}
          selecteBtn={selectedBtn}
          data={buttonInterventions}
          checkedButtons={checkedButtons}
          setCheckedButtons={setCheckedButtons}
        />
        {/* <button className="rounded-full px-5 py-1 w-fit border-[1.4px] text-[12px] flex justify-center items-center gap-2">
          A assigner
        </button> */}
      </div>
      <div>
       <InterventionsData interventions={interventions} />
      </div>
    </div>
  );
};
