import React from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const InterfacePublique = () => {
    const fields = ['name', 'phone', 'description', 'email'];
    const [selectedFields, setSelectedFields] = useState([]);
    const navigate = useNavigate();

    const handleCheckboxChange = (field) => {
        setSelectedFields((prevFields) =>
          prevFields.includes(field)
            ? prevFields.filter((item) => item !== field) 
            : [...prevFields, field] 
        );
      };

      const handleNavigate = () => {
        navigate('/interface-input', { state: { selectedFields } });
      };

    return (
        <div className="flex justify-center items-center min-h-screen overflow-auto  ">
            <div className="flex flex-col gap-4 p-4  border border-solid border-gray-500">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Inerface Publique</h2>
                <form className="space-y-4">
                    {fields.map((field) => (
                        <div key={field} className="flex items-center">
                        <input
                            type="checkbox"
                            id={field}
                            checked={selectedFields.includes(field)}
                            onChange={() => handleCheckboxChange(field)}
                            className="mr-2"
                        />
                        <label htmlFor={field} className="text-gray-800 capitalize">
                            {field}
                        </label>
                        </div>
                    ))}
                </form>
                <button
                    onClick={handleNavigate}
                    className="mt-6 bg-blue-500 text-white p-2 rounded"
                    >
                    Link
                </button>
            </div>
        </div>
    )
}

export default InterfacePublique