import React from "react";
import { useState, useEffect } from "react";
import api from "../utils/api";
import CustomDrawer from "../components/CustomDrawer";
import InterfacePubliqueDrawe from "../components/drawer/InterfacePubliqueDrawe";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import { Avatar, Checkbox } from "antd";
import Card from "../components/Card";
import { parametersData } from "../constant/constant";
import Icon from "../components/Icon";
import { useTranslation } from "react-i18next";
import Api from "../utils/api";
import SettingsClientDrawer from "../components/drawer/SettingsClientDrawer";
import { showSuccessToast, showErrorToast } from "../utils/toastConfig";

const Parameters = () => {
  const [isInterfacePublicOpen, setIsInterfacePublicOpen] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedFields, setSelectedFields] = useState([]);
  const [file, setFile] = useState(null);
  const [Link, setLink] = useState(null);
  const name = "PublicArea";
  const [fields, setFields] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [updatedData, setUpdatedData] = useState(parametersData);

  const handleOpenDrawer = (parameter) => {
    setFields(parameter.fields);
    setSelectedParameter(parameter);
    setIsDrawerOpen(true);
  };

  useEffect  (()  => {
    const fetchModules = async () => {
      try{
        const response = await api.get(`/modules`)
        setFetchedData(response.data);

      }catch(err) {
        console.log("failed to fetch Modules", err);
      }
    }
    fetchModules();
    
  },[])



  const updateData = () => {
    const updated = updatedData.map((item) => {
      const fetchedItem = fetchedData.find((data) => data.code === item.code);
  
      if (fetchedItem) {
        return { ...item, isActive: fetchedItem.isActive };
      }
      return item;
    });
    
    setUpdatedData(updated);
  };

  useEffect(() => {
    if (fetchedData.length > 0) {
      updateData();
    }
  }, [fetchedData]);

    const handleCloseDrawer = () => {
      setSelectedParameter(null);
      setIsDrawerOpen(false);
    }
    useEffect(() => {
      if (selectedParameter?.fields) {
        setSelectedFields(selectedParameter.fields.map((field) => field.name));
      } else {
        setSelectedFields([]); 
      }
    }, [selectedParameter]);

  const handleCheckboxChange = (field) => {
      setSelectedFields((prevFields) =>
        prevFields.includes(field)
          ? prevFields.filter((item) => item !== field) 
          : [...prevFields, field] 
      );
    };

  
    useEffect(() => {
      const fetchPublicAreaLink = async () => {
        try {
      
          const response = await api.post(`/modules/link/${name}`); 
          setLink(response.data.link);
        } catch (err) {
          console.log('Failed to fetch public areas'); 
        }
      };
      fetchPublicAreaLink(); 
    }, []);



  const handleSaveModel = async () => {
 
      handleCloseDrawer();
      const dataToSend = new FormData();

  
      dataToSend.append("code", selectedParameter.code);
      dataToSend.append("name", selectedParameter.name);
      dataToSend.append("isActive", selectedParameter.isActive);

      fields.forEach((field) => {
        const isSelected = selectedFields.includes(field.name); 
        
        dataToSend.append("settings[]", JSON.stringify({
          code: field.code,
          name: field.name,
          type: field.type,
          isActive: isSelected,
          isProtected: field.isProtected,
      
        }));
    
        
      });
    
      if (file) {
        dataToSend.append("image", file); 
      }
      try {
       const response =  await Api.post("/modules", dataToSend);
        showSuccessToast(`${selectedParameter.name} enregistré avec succès`)
        setFile(null);
      } catch (error) {
        showErrorToast(`${selectedParameter.name} non enregistré`);
        console.log(error);
      }
  }


  const handleModuleActive = async (selected) => {
    const updatedSelected = { ...selected, isActive: !selected.isActive };
    setSelectedParameter(updatedSelected);

    const updatedModules = updatedData.map((item) =>
      item.code === updatedSelected.code
        ? { ...item, isActive: updatedSelected.isActive }
        : item
    );
    setUpdatedData(updatedModules); 

    const dataToSend = {
      code: updatedSelected.code, 
      isActive: updatedSelected.isActive,  
    };


    try {
      const response = await Api.post("/modules/update", dataToSend);

    } catch (error) {
     
      showErrorToast("Error updating module")
      const revertedModules = updatedData.map((item) =>
        item.code === updatedSelected.code
          ? { ...item, isActive: !updatedSelected.isActive }
          : item
      );
      setUpdatedData(revertedModules); 
    }
  }


  
  return (
    <div className="flex justify-center items-center min-h-screen overflow-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {updatedData.map((item, index) => (
          <Card
            key={index}
            size="small"
            classNames="text-blue-950"
            item={item.name}
            title={
              <div className="flex items-center gap-2">
                <Icon name={`${item.icon}`} />
                {item.name}
              </div>
            }
            handleOpenDraw={() => handleOpenDrawer(item)}
            slected={selectedParameter}
            handleModuleActive={() => handleModuleActive(item)}
            isSetting = {item.isSetting}
            isActive={item.isActive}
            // extra={<Checkbox defaultChecked />}
            className="w-full sm:w-[400px] md:w-[400px] h-[12rem] p-5 rounded-lg"
          >
            <p className="p-5">{item.description}</p>
          </Card>
        ))}
        
      </div>
      {selectedParameter && (

        <CustomDrawer
        open={isDrawerOpen}
        width={600}
        onClose={handleCloseDrawer}
        footerEle={
            <FooterEle
              label={selectedParameter.label}
              icon={"Image"}  
              handleSubmit={handleSaveModel}
              file={file}
              setFile={setFile}
              save={true}
            /> 
          }
          title={
            <DrawerHeader title={selectedParameter.code} icon={selectedParameter.icon} />
          }
          >
   
              <SettingsClientDrawer
                fields={fields}
                setFields={setFields}
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
                handleCheckboxChange={handleCheckboxChange}
                Link={Link}
                name={selectedParameter.code}
                ifLink={selectedParameter.link}

              />
              
        </CustomDrawer>
      )}
    </div>
  );
};

export default Parameters;
