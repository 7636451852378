import React, { useState, useEffect } from 'react';
import api from "../utils/api"
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DrawerTabs from '../components/drawer/DrawerTabs';
import InterfacePubliqueTabs from "../components/drawer/InterfacePubliqueTabs";
import InputField from '../components/InputField';
import PhoneInputField from '../components/PhoneInput';
import {tabsInterfacePublique} from "../constant/constant";
import Icon from '../components/Icon';
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import ValideAnimation from "../svgs/ValideAnimation.json"
import LoadingBtn from "../svgs/LoadingBtn.json"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {PublicAreaTabs} from '../constant/constant'
import { Tag } from "antd";
import interventionStatusTagColor from '../utils/interventionStatusTagColor';
import InterventionDisplay from '../components/Public-area/InterventionDisplay'
import validateFields from '../utils/validateFields';
import { useNavigate } from 'react-router-dom';


const FieldDisplayPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {InterfaceId}  = useParams();
  const [selectedTab, setSelectedTab] = useState("FAIBLE");
  const [selectedTabArea, setSelectedTabArea] = useState("Créer un ticket");
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [trackId, setTrackId] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [errorInput, setErrorInput] =  useState(false);
  const [errorTrack, setErrorTrack] = useState(false);
  const [publicAreas, setPublicAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublicAreaActive, setIsPublicAreaPublic] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    intervenant: '',   
    fields: [],               
    status: 'new',  
    criticality: selectedTab,
    isPublicArea: true,
    id : InterfaceId,    
  });

  const name = "PublicArea";

    function convertDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      
      return `${day}/${month}/${year}`;
    }



  const handleTrackTicket = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      const phoneRegex = /^[0-9]{10}$/; 
  
      if (emailRegex.test(trackId)) {
        const response = await api.get(`/intervention/email/${trackId}`);
        setIntervention(response.data.intervention);
        console.log(response);
      } else if (phoneRegex.test(trackId)) {
        const response = await api.get(`/intervention/phone/${trackId}`);
        setIntervention(response.data.intervention);
        console.log(response);
      } else {
        console.error("Invalid trackId: Neither email nor phone number.");
        setIntervention(null);
        setErrorTrack(true);
      }


    } catch (err) {
      console.error(err);
      setErrorTrack(true);
      setIntervention(null);
    }
  };

  const handleInputChange = (e) => {
    setTrackId(e.target.value); 
  };

  const handleCreatNewTicket = (e) => {
    setFormData({
      intervenant: '',   
      fields: publicAreas
      .filter((field) => field.isActive) 
      .map((field) => ({
          name: field.name, 
          value: '',        
      })),               
      status: 'new',  
      criticality: selectedTab,
      isPublicArea: true,
      id: InterfaceId,    
    });
    setSelectedTab("FAIBLE")
    setIsSubmitted(false);
  }

  const handleFieldChange = (e, index) => {

    const { name, value, type, checked } = e.target;
    let isValid = true;
    let errorMessage = '';
    const fieldValue = type === "checkbox" ? checked : value;
    
    

    const updatedFields = [...formData.fields];
    updatedFields[index] = {
      code: publicAreas[index]?.code, 
      name: publicAreas[index]?.name, 
      value: fieldValue, 

    };
    
    setFormData((prevData) => ({
      ...prevData,
      
      fields: updatedFields, 
    }));
  };
  
  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      criticality: selectedTab,
    }));
  }, [selectedTab]);


  useEffect(() => {

    const fetchPublicAreas = async () => {
      try {
        const response = await api.get(`/modules/${name}/${InterfaceId}`); 
       
        const publicAreas = response.data.settings;
        if (!response.data.isActive) {
          navigate('/404'); 
          return; 
        }
     
        const updatedFields = publicAreas
            .filter((field) => field.isActive) 
            .map((field) => ({
                name: field.name, 
                value: '',        
            }));

        setFormData((prevData) => ({
            ...prevData,
            fields: updatedFields,
        }));
  
        setPublicAreas(response.data.settings);
        setBackgroundImage(response.data.image);
  
      } catch (err) {
        console.log('Failed to fetch public areas'); 
      }
    };

    fetchPublicAreas(); 

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { updatedFields, isValid } = validateFields(formData.fields);
    
    if (!isValid) {
      setFormData({
        ...formData,
        fields: updatedFields,
      });
      return; 
    }
    setIsLoading(true);
    try {
      await api.post("/intervention", formData);

     
      setIsSubmitted(true);
 
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
    }
  }
  

   return (
  
    <div className="min-h-screen bg-gray-100 p-6 flex flex-col gap-5 items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',   
        backgroundPosition: 'center'
      }}
    >
      <div className='flex  max-w-md w-full '>
        <DrawerTabs data={PublicAreaTabs} selectedTab={selectedTabArea} setSelectedTab={setSelectedTabArea}/>
      </div>
      <div
    className={`transition-all duration-500 ease-in-out flex flex-col gap-5 max-w-md w-full `} 
    style={{
      height: selectedTabArea === "Créer un ticket" 
        ? isSubmitted 
          ? '700px' 
          : '700px' 
        : '700px', 
    }}
  >

      {selectedTabArea === "Créer un ticket" ? ( 
        
        isSubmitted ? (
          <div className="flex flex-col items-center justify-center gap-2 max-w-md w-full p-6 bg-white shadow-lg rounded-lg  overflow-y-auto">
            <div className="text-green-500 ">
              <Lottie style={{height: "130px"}} animationData={ValideAnimation} loop={false} />
            </div>
            <div className='flex flex-col gap-2'>
              <p className="text-[#8ccec2] mt-2 font-bold text-center">Votre ticket a été créé avec succès !</p>
              <p className="text-[#1f221b] text-[14px] text-center">Vous pouvez suivre l'état de votre ticket en utilisant l'email ou le numéro de téléphone que vous avez fourni.</p>
            </div>
              <button
                onClick={handleCreatNewTicket}
                className="mt-4 px-3 text-white items-center bg-orange rounded-lg py-2 flex gap-5"
              >
                <p>Créer un autre ticket</p>
                <Icon name="Plus" />
              </button>
          </div>
        ) : (
          <div className="flex flex-col gap-5 max-w-md w-full p-6 bg-white shadow-lg rounded-lg overflow-y-auto">
          <div> 

            <InterfacePubliqueTabs data={tabsInterfacePublique} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-4">
          {errorInput && (
            <div style={{ color: 'red', fontSize: '12px' }}>
              error
            </div>
          )

          }
          {publicAreas.map((field, index) => (
                field.isActive && (
                  
                  <div key={field.name} className="flex flex-col">
             
                {field.type === 'text' && (
                    <>
                      <InputField
                        handleChange={(e) => handleFieldChange(e, index)} 
                        value={formData.fields[index]?.value || ''} 
                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                        disabled={isLoading}
                      />
                      {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )}
                  {field.type === 'tel' && (
                    <>
                      <PhoneInputField
                        handleChange={(value) => handleFieldChange({ target: { name: field.name, value } }, index)}
                        value={formData.fields[index]?.value || ''} 
                        type={field.type}
                        name={field.name}
                        placeholder={field.name}
                        disabled={isLoading}
                        />
                        {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )}
                  
                  {field.type === 'textarea' && (
                    <>
                      <InputField
                          handleChange={(e) => handleFieldChange(e, index)} 
                          value={formData.fields[index]?.value || ''}
                          type={field.type}
                          name={field.name}
                          placeholder={field.name}
                          disabled={isLoading}
                          />
                        {formData.fields[index]?.error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {formData.fields[index].error}
                        </div>
                      )}
                    </>
                  )} 
                  {field.type === 'checkbox' && (
                    <div key={field} className="flex items-center">
                    <input
                              type="checkbox"
                              id={field.name}
                              className="mr-2"
                          />
                          <label htmlFor={field} className="text-gray-800 capitalize">
                              {field.name}
                          </label>
                        </div>
                  )} 
                   {field.type === 'location' && (
                     <div className='relative'>
      
                        <div className={`absolute left-3 transition-all -top-3 bg-white text-sm  px-1 text-dark font-medium z-10 `} >
                            {t("address")}
                        </div>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY} 
                            selectProps={{
                            value: formData.address,
                            onChange: (value) => handleFieldChange({ target: { name: field.name, value: value } }, index),  
                            styles: {
                                input: (provided) => ({
                                  ...provided,
                                  margin: '8px',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                margin: '8px',
                                }),
                                option: (provided) => ({
                                ...provided,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                margin: '8px',
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: '8px',
                                }),
                            },
                            }}
                        />
                    </div>
                  )} 
                  </div>
                )
              ))}
        
            <div className="flex justify-end">
              <button
                onClick={handleSubmit}
                className="relative w-[100px] h-[50px] px-3 text-white items-center bg-orange rounded-lg py-2 flex gap-5"
                disabled={isLoading}
              >
                <>
                {!isLoading ? (
                  <> 
                    <p>{t("createBtn")}</p>  
                    <Icon name="Plus" size={18} />
                  </>
                ) : (
                  <Lottie  animationData={LoadingBtn}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  />

                )}
                </>
              </button>
            </div>
            </form>
      </div>
              )
    ) : (
      <div className='flex flex-col gap-5 max-w-md w-full p-6 bg-white shadow-lg rounded-lg  overflow-y-auto justify-center '>
        {!intervention ? (
          
          <>
            <p className="text-black-400 mt-2 ">Veuillez entrer l'adresse e-mail ou le numéro de téléphone que vous avez utilisé lors de l'enregistrement pour suivre votre ticket :</p>

            <div>
                <InputField
                  handleChange={handleInputChange}
                  placeholder="E-mail / Numéro de téléphone"
                  type="text"
                  name="E-mail / Numéro de téléphone"
                  value={trackId}
                  
                  />
                {errorTrack && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                        L'adresse e-mail ou le numéro de téléphone est incorrect
                  </div>
                  )}
            </div>
            <div className="flex justify-end">
                <button
                  onClick={handleTrackTicket}
                  className='px-3 text-white items-center bg-orange rounded-lg py-2 flex gap-5'
                  >
                  <>
                    <p>Suivre</p>
                    <Icon name="Plus" size={18} />
                  </>
                </button>
            </div>
          </>
          ) : (
          
              <InterventionDisplay interventions={intervention} />
            )}
      </div>
      )
      }
      </div>
    </div>
   
  );
};


export default FieldDisplayPage;
