import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import Icon from "../Icon";
import { Tag } from 'antd';
import ContartInput from "./ContartInput";
import validateFields from "../../utils/validateFields";
import ContartInputEdit from "./ContartInputEdit";

const ContractDetail = () => {
  const { activeClient } = useSelector((state) => state.clientReducer);
  const [Contracts, setContracts] = useState([]);
  const [isAddContrat, setIsAddContrat] = useState(false);
  const [isEditContrat, setIsEditContrat] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [formData, setFormData] = useState({
    fields: [],
  });

  const onEditContract = (contract) => {
    setIsEditContrat(true);
    setFormData({ fields: contract.fields  });
    setIsEditing(contract._id);
  };

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const response = await api.get(`/contrat/${activeClient?.clientOrgId}`);

        setContracts(response.data.contrats || []); 
      } catch (err) {
        setContracts([]);
        console.error("Failed to fetch contracts", err);
      }
    };

    if (activeClient?.clientOrgId) {
      fetchContract();
    }
  }, [activeClient]);

  function convertDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    
    return `${day}/${month}/${year}`;
  }

  const handleSubmit = async (e) => {

    const { updatedFields, isValid } = validateFields(formData.fields);

    if (!isValid) {
      setFormData({
        ...formData,
        fields: updatedFields,
      });
      return;
    }
    try {
      const dataContrat = {
        fields: formData,
        id: activeClient?.clientOrgId,
      }
      
      const response = await api.post("/contrat", dataContrat);
      if (response.data.contrat) {
        setContracts((prevContracts) => [
          ...prevContracts, 
          response.data.contrat, 
        ]);
        setIsAddContrat(false);
      };
    } catch (error) {
      console.error("Error while sending contrat data:", error);
    }
  }

  const handleEdit = async () => {
    try {
      const dataContrat = {
        fields: formData.fields, 
      };
  
      const response = await api.put(`/contrat/${isEditing}`, dataContrat);
  
      if (response.data.contrat) {

        setContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract._id === isEditing ? response.data.contrat : contract
          )
        );
      }

      setIsEditContrat(false);
      setIsEditing(null);
    } catch (error) {
      console.error("Error while updating contrat data:", error);
    }
  };

  const handleDelete = async (contractId) => {
    try {
      setIsDeleteLoading(true);
      await api.delete(`/contrat/${contractId}`); 
    
      setContracts((prevContracts) =>
        prevContracts.filter((contract) => contract._id !== contractId)
      );

      setIsDeleteLoading(false); 
    } catch (error) {
      console.error("Error while deleting contract:", error);
      setIsDeleteLoading(false); 
    }
  };


  return (

  
    <div className="overflow-x-hidden">
      {Contracts?.length > 0 ? (

        Contracts.map((contract, index) => {

          const startDateField = contract.fields?.find(
            (field) => field.code === "startDate"
          );
          const endDateField = contract.fields?.find(
            (field) => field.code === "endDate"
          );
          const Title = contract.fields?.find(
            (field) => field.code === "title"
          );
          const Place = contract.fields?.find(
            (field) => field.code === "place"
          );
          
          const startDate = startDateField
            ? new Date(startDateField.value)
            : null;
          const endDate = endDateField
            ? new Date(endDateField.value)
            : null;
        
          const currentDate = new Date();
          const isExpired = endDate && endDate < currentDate;
        
          return (
           <div className=" flex group  transition  ease-in cursor-pointer relative w-full  items-center px-4 py-5 border-b border-gray-200 hover:bg-gray-100 bg-white"> 
            <div className="flex flex-col  transition  ease-in cursor-pointer relative w-full  items-center "> 
 
              <div
                key={index}
                className="flex transition  ease-in cursor-pointer relative w-full  items-center "
                >
                    <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-2">
                      <h3 className="text-[16px] font-bold">
                        {Title?.value}
                      </h3>
                    </div>
                    <div className="flex items-center gap-4">
                      <div><Icon name={"Calendar"} color="gray" /></div>
                      <div>
                        <h4 class="text-slate-700 font-bold text-[14px] ">
                            Start:- {convertDate(startDate)}
                        </h4>
                      </div>
                  </div>
                  <div className="flex items-center gap-4">
                      <div><Icon name={"Calendar"} color="gray" /></div>
                      <div>
                        <h4 className="text-slate-700 font-bold text-[14px] ">
                            End:- {convertDate(endDate)}
                        </h4>
                      </div>
                  </div>
                  <div className="flex w-full justify-self-auto gap-1">
                    <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-4">
                      <Icon name={"MapPin"} size={15} color={"gray"} />
                      {Place?.value|| "No Address"}
                    </p>
                      <div className="absolute top-0 right-2">
                        {isExpired ? (
                          <Tag color="red">Expiré</Tag> 
                        ) :(
                          <Tag color="green">Actif</Tag> 
                        )}
                      </div>
                    
                  </div>
                  </div>
              </div>
              
       
            </div>
            <div className="flex flex-col h-full items-center justify-center absolute top-0 right-0 bg-gradient-to-r from-transparent to-gray-300 opacity-0 group-hover:opacity-100 transition-all duration-300">
              <div
                onClick={() => onEditContract(contract)}
                className="flex p-2 transition ease-in cursor-pointer relative w-full items-center justify-center"
              >
                <Icon
                  name="Pencil"
                  size={18}
                  className="ml-auto opacity-0 hover:scale-105 group-hover:opacity-100 transition-opacity duration-200"
                />
              </div>
              
              <button
                onClick={() => handleDelete(contract._id)}
                disabled={isDeleteLoading}
                type="button"
                className="p-2 hover:scale-105 transition ease-in-out duration-300 flex items-center justify-center ml-auto opacity-0 group-hover:opacity-100 group-hover:scale-105"
              >
                <Icon className="hover:scale-105"   size={18} name="Trash2" />
              </button>
            </div>

          </div>
          );
        })
      ) : (
        <p className="text-center text-gray-500">No contracts found</p>
      )}
      {/* {Contracts?.length > 0  && ( */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsAddContrat(!isAddContrat)}    
            className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto "
            >
            <p>Ajouter un nouveau contrat</p>
            <Icon name="Pencil" size={18} />
          </button>
        </div>
      {/* )} */}
      {isAddContrat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="flex flex-col w-full max-w-md gap-3 px-4 py-8 border-b border-gray-200 rounded-lg bg-white">
            <button
              onClick={() => setIsAddContrat(false)}
              className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 text-white bg-orange hover:bg-orange rounded-full flex items-center justify-center ml-auto shadow-lg"
            >
              <Icon name="X" />
            </button>
            <ContartInput formData={formData} setFormData={setFormData} />
            <button
              onClick={handleSubmit}
              className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto"
            >
              <p>Save</p>
              <Icon name="Pencil" size={18} />
            </button>
          </div>
        </div>
      )}
       {isEditContrat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="flex flex-col w-full max-w-md gap-3 px-4 py-8 border-b border-gray-200 rounded-lg bg-white">
 
            <button
              onClick={() => setIsEditContrat(false)}
              className="p-2 hover:scale-105 h-8 w-8 transition ease-in-out duration-300 text-white bg-orange hover:bg-orange rounded-full flex items-center justify-center ml-auto shadow-lg"
            >
              <Icon name="X" />
            </button>
            <ContartInputEdit formData={formData} setFormData={setFormData} />
            <button
              onClick={handleEdit}
              className="p-2 gap-2 bg-orange hover:shadow-lg transition ease-in text-white rounded flex items-center justify-center ml-auto"
            >
              <p>Save</p>
              <Icon name="Pencil" size={18} />
            </button>
          </div>
        </div>
      )}
    </div>

  );
};

export default ContractDetail;
