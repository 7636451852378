import React, { createContext, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { removeToken, removeUser } from "../utils/localStorage";
import Icon from "./Icon";
import { useAppContext } from "../context/Context";
import { useTranslation } from "react-i18next";

const SidebarContext = createContext();

const Sidebar = ({ children, expanded, setExpanded }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/signin");
  };
  return (
    <aside className="h-screen bg-white fixed">
      <div>
        <div></div>
      </div>
      <div
        className="absolute top-20 right-0 translate-x-1/2 -translate-y-1/2  p-1 rounded-full cursor-pointer shadow bg-dark"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon
          name={!expanded ? "ChevronRight" : "ChevronLeft" }
          size="11"
          className={"text-white"}
        />
      </div>
      <div className="flex items-center justify-center h-20">
        {
          expanded ? (
            <div className=" w-52">
              <img src="images/plan-long-logo.png" />
            </div>
          ) : (
            <div className="w-8 ">
              <img src="images/plan-short-logo.png" />
            </div>
          )
          // <Icon name="Calendar" className={"text-orange"}/>
        }
      </div>
      <nav
        className={`flex  h-[calc(100%-65px)]  flex-col justify-between pb-6 mt-4 ${
          expanded ? "w-60" : "w-16"
        }`}
      >
        <SidebarContext.Provider value={{ expanded, setExpanded }}>
          <ul
            className={`flex-1 flex gap-2 flex-col  ${
              expanded ? "w-60" : "w-16"
            }`}
          >
          
            {children}
            
          </ul>
        </SidebarContext.Provider>

        <div className="  flex flex-col">
          <div
            // onClick={() => handleLogout()}
            className=" cursor-pointer pl-6 flex p-3"
          >
            <Icon name="Settings" />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Profil</div>
            </div>
          </div>
          <div
            onClick={() => handleLogout()}
            className=" cursor-pointer pl-6 flex p-3"
          >
            <Icon name="LogOut" />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Logout</div>
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;

export function SidebarItem({
  icon,
  text,
  path,
  active,
  tabName,
  setTabName,
  role,
}) {
  const { t } = useTranslation();
  const { expanded, setExpanded } = useContext(SidebarContext);
  const { userData } = useAppContext();

  return (
    <>
      {userData.role === "publisher" &&
      ["staff", "Invitations"].indexOf(text) !== -1 ? (
        ""
      ) : (
        <Link
          to={path}
          onClick={() => setTabName(text)}
          className={`
        relative flex items-center p-2  justify-center
        font-medium  cursor-pointer mx-3 rounded
        transition-colors group  text-dark
        ${
          tabName === text
            ? "bg-dark text-white"
            : "hover:bg-dark hover:text-white "
        }
    `}
        >
          {typeof icon === "string" ? <Icon name={icon} /> : icon}
          <span
            className={`overflow-hidden  transition-none text-[16px] font-normal leading-5 ${
              expanded ? "w-52 ml-3" : "w-0"
            }`}
          >
            {t(text)}
          </span>

          {!expanded && (
            <div
              className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-light text-dark text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
      `}
            >
              {t(text)}
            </div>
          )}
        </Link>
      )}
    </>
  );
}
