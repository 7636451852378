import React from "react";
import { useEffect, useState } from "react";
import api from "../../../utils/api";
import InputComp from "../../InputComp";
import SelectButton from "../../SelectButton";
import { LocationData, buttonEquipment } from "../../../constant/constant"
import { Divider } from "antd";
import Icon from "../../Icon";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedIntervention, updateIntervention } from "../../../redux/slices/interventionSlice";


const AssignedInterventionDocument = () => {
    const { selectedIntervention } = useSelector(state => state.interventionReducer);
    const [documents, setDocuments] = useState([]);
    const [selectedBtn, setSelectedBtn] = useState(0);
    const [inputData, setInputData] = useState("");
  
    console.log(selectedIntervention)

    useEffect(() => {

        const fetchDocuments = async () => {
          try {
            const response = await api.get(`/document/${selectedIntervention._id}`); 
            setDocuments(response.data);
            
          } catch (err) {
            console.log('Failed to fetch Documents'); 
          }
        };
    
        fetchDocuments(); 
    
      }, [selectedIntervention]);

      const handleDelete = async (documentId) => {
     
        try {
          const response = await api.delete(`/document/${documentId}`);
    
          if (response.status === 200) {
           
            setDocuments(documents.filter((doc) => doc._id !== documentId));
    
      
          }
        } catch (err) {
          console.error("Error deleting document:", err);
  
        }
      };
  
    return (
          <div className="h-full p-4 rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
          <div className="flex flex-col gap-4 w-full p-4">
            <InputComp setInputData={setInputData} inputData={inputData} />
            <SelectButton
              setSelecteBtn={setSelectedBtn}
              selecteBtn={selectedBtn}
              data={buttonEquipment}
            />
          </div>
          <div>
            {documents.map((location, index) => {
              return (
                <>
                  <div
                    key={location.id}
                    className="flex gap-4 justify-between items-center"
                  >
                    <div className="flex gap-4 items-center">
                      <div className="flex flex-col">
                        <h1 className="text-[16px] font-bold">
                          {location.fileName}
                        </h1>
                        <p>{location.paragraph}</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button className="text-gray-500 hover:text-gray-700 ml-4 relative"
                        onClick={() => {
                          const link = document.createElement("a");
                          link.href = location.fileUrl; 
                          link.download = location.fileName; 
                          link.click();
                        }}
                      >
                        <Icon size="25" name={"ClipboardList"} color="gray" />
                        {/* <span className="absolute top-[-5px] right-[-5px] bg-blue-950	h-4 w-4 text-white text-xs rounded-full px-1 ">
                          1
                        </span> */}
                
                      </button>
                      <button className="text-gray-500 hover:text-gray-700 ml-2"
                        onClick={() => handleDelete(location._id)}
                      >
                        <Icon size="20" name="Trash2" color="gray" />
                      </button>
                    </div>
                  </div>
                
                  <Divider />
                  
                </>
              );
            })}
          </div>
        </div>
    )
}

export default AssignedInterventionDocument;