import React, { useState, useEffect, useDebugValue } from "react";
import Icon from "./Icon";
import Sidebar, { SidebarItem } from "./Sidebar";
import { useAppContext } from "../context/Context";
import { tabsLabel } from '../constant/constant'
import { getUser } from "../utils/localStorage";
import api from "../utils/api";
import { useMemo } from "react";
// const linkArr = [
//   {
//     id: 1,
//     label: "Home",
//     icon: <Icon name="LayoutDashboard" />,
//     path: "/",
//     roles: ["org", "intervenants"]
//   },
//   {
//     id: 6,
//     ...tabsLabel.clients,
//     path: "/clients",
//     roles: ["org"],
//     // isActive: modules.find(module => module.code === "Client")?.isActive || false
//   },
//   {
//     id: 7,
//     ...tabsLabel.intervenants,
//     path: "/intervenants",
//     roles: ["org"]
//   },
//   {
//     id: 8,
//     ...tabsLabel.locations,
//     path: "/localisation",
//     roles: ["org"]
//   },
//   {
//     id: 9,
//     ...tabsLabel.equipements,
//     path: "/equipment",
//     roles: ["org"]
//   },
//   {
//     id: 10,
//     ...tabsLabel.interventions,
//     path: "/interventions",
//     roles: ["org"]
//   },
//   {
//     id: 11,
//     ...tabsLabel.interventions,
//     path: "/intervenant/interventions",
//     roles: ["intervenant"]
//   },
//   {
//     id: 12,
//     ...tabsLabel.interventions,
//     path: "/client/interventions",
//     roles: ["client"]
//   },
//   {
//     id: 13,
//     label: "UserList",
//     icon: <Icon name="Users" />,
//     path: "/users",
//     roles: ["org"]
//   },
//   {
//     id: 14,
//     label: "Parameters",
//     icon: <Icon name="Settings2" />,
//     path: "/parameters",
//     roles: ["org"]
//   },
// ];



const BigSidebar = ({ expanded, setExpanded }) => {
  const [tabName, setTabName] = useState("Home");
  const { userData } = useAppContext();
  const [sidebarTabs, setSidebarTabs] = useState([]);
  const [modules, setModule] = useState([]);

  useEffect  (()  => {
    const fetchModules = async () => {
      try{
        const response = await api.get(`/modules`)
        setModule(response.data);
  
      }catch(err) {
        console.log("failed to fetch Modules", err);
      }
    }
    fetchModules();
    
  },[])
  const linkArr = useMemo(() => [
    {
      id: 1,
      label: "Home",
      icon: <Icon name="LayoutDashboard" />,
      path: "/",
      roles: ["org", "intervenants"]
    },
    {
      id: 6,
      ...tabsLabel.clients,
      path: "/clients",
      roles: ["org"],
      isActive: modules.find(module => module.code === "Client")?.isActive || false
    },
    {
      id: 7,
      ...tabsLabel.intervenants,
      path: "/intervenants",
      roles: ["org"]
    },
    {
      id: 8,
      ...tabsLabel.locations,
      path: "/localisation",
      roles: ["org"]
    },
    {
      id: 9,
      ...tabsLabel.equipements,
      path: "/equipment",
      roles: ["org"]
    },
    {
      id: 10,
      ...tabsLabel.interventions,
      path: "/interventions",
      roles: ["org"]
    },
    {
      id: 11,
      ...tabsLabel.interventions,
      path: "/intervenant/interventions",
      roles: ["intervenant"]
    },
    {
      id: 12,
      ...tabsLabel.interventions,
      path: "/client/interventions",
      roles: ["client"]
    },
    {
      id: 13,
      label: "UserList",
      icon: <Icon name="Users" />,
      path: "/users",
      roles: ["org"]
    },
    {
      id: 14,
      label: "Parameters",
      icon: <Icon name="Settings2" />,
      path: "/parameters",
      roles: ["org"]
    },
  ], [modules])
  
  useEffect(() => {
    setSidebarTabs(linkArr);
  },[linkArr])
  

 
  const filterSiderTabs = () => {
    const user = getUser();
    const role = user?.role || "org";
  
    const filteredTabs = linkArr.filter(
      (tab) =>
          tab.roles.includes(role) && (!tab.hasOwnProperty("isActive") || tab.isActive));
  
    return filteredTabs;
  };

  useEffect(() => {
    const path = window.location.pathname;
    const activeTab = linkArr.find((link) => path === link.path);
    setTabName(activeTab?.label || "Home");
    const filteredTabs = filterSiderTabs();
    setSidebarTabs(filteredTabs);
  }, [linkArr]);

  return (
    <main className="z-[100] h-screen">
      <Sidebar expanded={expanded} setExpanded={setExpanded}>
          {sidebarTabs.map((link) => {
          return (
            <SidebarItem
              role={userData.role}
              icon={link.icon}
              text={link.label}
              path={link.path}
              key={link.id}
              tabName={tabName}
              setTabName={setTabName}
            />
          );
        })}
      </Sidebar>
    </main>
  );
};

export default BigSidebar;
